import cx from 'classnames';
import { FormEvent, TransitionEvent, useEffect, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { ICONS } from '../../constants/icons';
import { useAppContext } from '../../contexts/AppContext/AppContext';
import { useOverlayContext } from '../../contexts/OverlayContext/OverlayContext';
import { useTimeout } from '../../hooks/useTimeout';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { Reveal } from '../Reveal/Reveal';
import { RevealText } from '../RevealText/RevealText';
import { View } from '../View/View';
import styles from './Login.module.scss';

interface LoginProps {
  onSuccess?: () => void;
}

export const Login = ({ onSuccess }: LoginProps) => {
  const { isMobile, setTheme, isPage } = useAppContext();
  const [hide, setHide] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState<any>('');
  const { setSplash, setMap } = useOverlayContext();
  const { tidRef } = useTimeout();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const password = event.currentTarget.password.value;
    if (!password) {
      setError('Empty value');
      return;
    }
    setError('');
    setFetching(true);
    const body = JSON.stringify({ password });
    const res = await fetch('/api/login', {
      method: 'post',
      credentials: 'include',
      body,
      headers: { 'Content-Type': 'application/json' },
    });
    if (res.status !== 200) {
      const { message } = await res.json();
      tidRef.current = setTimeout(() => {
        setError(message);
        setFetching(false);
      }, 200);
      return;
    }

    tidRef.current = setTimeout(() => {
      setHide(true);
    }, 2000);

    if (!isPage.landing) {
      setMap({ visible: true });
    }
  };

  const onTransitionEnd = (event: TransitionEvent<HTMLElement>) => {
    const { propertyName } = event;
    if (propertyName !== 'opacity' || !onSuccess) return;
    tidRef.current = setTimeout(() => {
      onSuccess();
    }, 300);
  };

  useEffect(() => {
    setTheme('dark');
    setSplash({ visible: true });
    return () => {
      if (isPage.landing) return;
      setTheme('light');
      setSplash({ visible: true, open: true });
    };
  }, []);

  return (
    <View
      alignContent="center"
      justifyContent="center"
      sidePadding={16}
      fullscreen="absolute"
      as="main"
      className={cx(styles.login, hide && styles.hide)}
      onTransitionEnd={hide ? onTransitionEnd : undefined}
    >
      <RevealText variant="button-labels" as="p" when letterDelay={60}>
        LAUNCHING AUGUST 31st
      </RevealText>

      <RevealText
        variant={isMobile ? 'headline-l-bold' : 'headline-xl-bold'}
        as="p"
        className={styles.title}
        when
        letterDelay={20}
        delay={500}
      >
        NFTs that fund wildfire prevention in California.
      </RevealText>
      <Reveal when delay={1000} className={styles.reveal}>
        <View as="form" onSubmit={onSubmit} className={styles.form}>
          <Input
            label="password"
            type="password"
            name="password"
            bgColor={COLORS.NEUTRAL_01}
            variant={isMobile ? 'md' : 'lg'}
            error={error}
          />
          <View className={styles.buttonContainer}>
            <Button
              type="submit"
              variant="primary"
              icon={isFetching ? ICONS.LOADING_WHITE : undefined}
              tag="click_password_enter"
            >
              {isFetching ? undefined : 'ENTER'}
            </Button>
          </View>
        </View>
      </Reveal>
    </View>
  );
};
