import { useEffect, useRef } from 'react';

export const useTimeout = () => {
  const tidRef = useRef<NodeJS.Timeout | null>(null);

  const killTimeout = () => {
    if (tidRef.current) {
      clearTimeout(tidRef.current);
    }
  };

  useEffect(() => {
    killTimeout();
    return () => {
      killTimeout();
    };
  }, []);

  return { tidRef, killTimeout };
};
