import React, { ImgHTMLAttributes } from 'react';
import { ICONS } from '../../constants/icons';

export interface IconProps extends ImgHTMLAttributes<HTMLImageElement> {
  variant: ICONS;
}

export const Icon = ({
  variant,
  alt = '',
  loading = 'lazy',
  ...props
}: IconProps) => {
  return (
    <img src={variant as unknown as string} alt={alt} loading={loading} {...props} />
  );
};

export const getIcon = (icon?: ICONS | string) =>
  ICONS[icon as number] as unknown as ICONS;
