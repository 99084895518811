import { ROUTES } from '../../constants/routes';
import { IsPage } from './AppContext';

export const getIsPage = (route: string, { history }: IsPage) => {
  const isPage: IsPage = { history };
  if (history[history.length - 1] !== route && !route.includes(']')) {
    isPage.history.push(route);
  }

  if (route === ROUTES.LANDING) {
    isPage.landing = true;
  } else if (route.startsWith(ROUTES.ADMIN)) {
    isPage.admin = true;
  } else if (route.startsWith(ROUTES.LANDSCAPES)) {
    isPage.landscapes = true;
  } else if (route.startsWith(ROUTES.REGIONS)) {
    isPage.regions = true;
  } else if (route.startsWith(ROUTES.ABOUT)) {
    isPage.about = true;
  } else if (route === ROUTES.ARTICLE) {
    isPage.article = true;
  } else if (route === ROUTES.MAP) {
    isPage.map = true;
  } else if (route === ROUTES.CONFIRMATION) {
    isPage.confirmation = true;
  } else if (route === ROUTES.PRIVACY_POLICY) {
    isPage.privacyPolicy = true;
  }

  isPage.initialLanding = isPage.landing && isPage.history.length === 1;

  return isPage;
};
