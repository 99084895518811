import { useEffect, SetStateAction } from 'react';
import { BREAK_POINT } from '../../constants/config';
import { AppContextModel } from './AppContext';

export const useMatchMedia = (
  setAppContextModel: (value: SetStateAction<AppContextModel>) => void
) => {
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${BREAK_POINT}px)`);
    const handleTabletChange = ({ matches }: { matches: boolean }) => {
      const isMobile = !matches;
      const isDesktop = matches;
      setAppContextModel((prev) => ({ ...prev, isMobile, isDesktop }));
    };
    mediaQuery.addEventListener('change', handleTabletChange);
    handleTabletChange({ matches: mediaQuery.matches });
    return () => {
      mediaQuery.removeEventListener('change', handleTabletChange);
    };
  }, []);
};
