import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useState } from 'react';
import { Logo } from '../../components/Logo/Logo';
import { SplashBackground } from '../../components/SplashBackground/SplashBackground';
import { Toast, ToastVariant } from '../../components/Toast/Toast';
import { useGraph } from '../../hooks/useGraph';
import { useTimeout } from '../../hooks/useTimeout';
import { getErrorMessage } from '../../lib/firewatch-script/getErrorMessage';
import { useAppContext } from '../AppContext/AppContext';

interface OverlayLandscapePanel {
  forceOpen?: boolean;
  open?: boolean;
  visible?: boolean;
}

interface OverlayMap {
  // TODO: use for map image loading
  loaded?: boolean;
  visible?: boolean;
}

interface OverlaySplash {
  loaded?: boolean;
  open?: boolean;
  visible?: boolean;
}

interface OverlayToast {
  message: string;
  variant?: ToastVariant;
  fromOrderApi?: boolean;
}

export interface OverlayContextModel {
  landscapePanel?: OverlayLandscapePanel;
  map?: OverlayMap;
  splash?: OverlaySplash;
  toast?: OverlayToast;
}

interface OverlayContextAction {
  setLandscapePanel: (item?: OverlayLandscapePanel, merge?: boolean) => void;
  setMap: (item?: OverlayMap, merge?: boolean) => void;
  setSplash: (item?: OverlaySplash, merge?: boolean) => void;
  setToast: (item?: OverlayToast, merge?: boolean) => void;
}

export type OverlayContextState = OverlayContextModel & OverlayContextAction;

export const initialOverlayContextModel: OverlayContextModel = {};

const initialOverlayContextAction: OverlayContextAction = {
  setLandscapePanel: () => {},
  setMap: () => {},
  setSplash: () => {},
  setToast: () => {},
};

const OverlayContext: React.Context<OverlayContextState> =
  React.createContext<OverlayContextState>({
    ...initialOverlayContextModel,
    ...initialOverlayContextAction,
  });

export const OverlayContextProvider: React.FC<{
  children: ReactNode;
  initialContext?: OverlayContextModel;
}> = ({ children, initialContext }) => {
  const { route } = useRouter();
  const { isPage } = useAppContext();
  const [overContextModel, setOverlayContextModel] =
    React.useState<OverlayContextModel>(
      initialContext || { ...initialOverlayContextModel }
    );

  const setLandscapePanel = (item?: OverlayLandscapePanel, merge?: boolean) =>
    setOverlayContextModel((prev) => {
      const landscapePanel = merge ? { ...prev.landscapePanel, ...item } : item;
      return { ...prev, landscapePanel };
    });

  const setMap = (item?: OverlayMap, merge?: boolean) =>
    setOverlayContextModel((prev) => {
      const map = merge ? { ...prev.map, ...item } : item;
      return { ...prev, map };
    });

  const setSplash = (item?: OverlaySplash, merge?: boolean) =>
    setOverlayContextModel((prev) => {
      const splash = merge ? { ...prev.splash, ...item } : item;
      return { ...prev, splash };
    });

  const setToast = (item?: OverlayToast, merge?: boolean) => {
    if (!item) {
      if (merge) return;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setOverlayContextModel(({ toast, ...prev }) => prev);
    } else {
      setOverlayContextModel((prev) => {
        const toast = merge ? { ...prev.toast, ...item } : item;
        return { ...prev, toast };
      });
    }
  };

  const { error } = useGraph();
  useEffect(() => {
    if (!error) return;
    const message = getErrorMessage(error);
    setToast({ message, variant: 'error' });
  }, [error]);

  useEffect(() => {
    setToast();
  }, [route]);

  useEffect(() => {
    const forceOpen = overContextModel.landscapePanel?.forceOpen;
    const visible = isPage.map || isPage.landscapes || isPage.regions;
    const open =
      typeof forceOpen === 'boolean'
        ? forceOpen
        : isPage.landscapes || isPage.regions;
    setLandscapePanel({ open, visible });
  }, [isPage]);

  return (
    <OverlayContext.Provider
      value={{
        ...overContextModel,
        setLandscapePanel,
        setMap,
        setSplash,
        setToast,
      }}
    >
      <FWLogo />
      <SpashScreen />
      {overContextModel?.map?.visible && <Map />}
      {children}
      {overContextModel.toast && (
        <Toast {...overContextModel.toast} onClose={() => setToast()} />
      )}
    </OverlayContext.Provider>
  );
};

export const useOverlayContext = () => React.useContext(OverlayContext);

function FWLogo() {
  const { tidRef } = useTimeout();
  const { theme } = useAppContext();
  const { map } = useOverlayContext();
  const [withText, setWithText] = useState(false);

  useEffect(() => {
    tidRef.current = setTimeout(() => {
      setWithText(true);
    }, 2000);
  }, []);

  return <Logo withText={withText} theme={theme} disabled={!map?.visible} />;
}

function SpashScreen() {
  const { splash: { visible, open } = {}, setSplash } = useOverlayContext();
  return (
    <SplashBackground
      {...{ visible, open }}
      onShow={() => setSplash({ visible, open, loaded: true })}
    />
  );
}

const Map = dynamic(async () => (await import('../../components/Map/Map')).Map, {
  ssr: false,
});
