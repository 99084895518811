import cx from 'classnames';
import {
  ElementType,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ForwardedRef,
} from 'react';
import { COLORS } from '../../constants/colors';
import { Theme } from '../../constants/config';
import { TextVariant } from '../Text/Text';
import textStyles from '../Text/Text.module.scss';
import styles from './View.module.scss';

export interface ViewProps<T = HTMLElement>
  extends PropsWithChildren<HTMLAttributes<T>> {
  as?: ElementType;
  gap?: number | string;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  flow?:
    | 'row'
    | 'column'
    | 'dense'
    | 'row dense'
    | 'column dense'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';
  theme?: Theme;
  text?: TextVariant;
  verticalPadding?: number | string;
  topPadding?: number | string;
  bottomPadding?: number | string;
  rightPadding?: number | string;
  leftPadding?: number | string;
  sidePadding?: number | string;
  sideVariant?: 'none' | 'sm' | 'md' | 'lg' | 'x-lg';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  justifyContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  alignContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  fullscreen?: 'fixed' | 'absolute';
  withBottomDevider?: boolean;
  maxWidth?: number | string;
  backgroundColor?: COLORS;
}

const ViewInner = <T extends HTMLElement>(
  {
    as: Tag = 'div',
    className,
    gap,
    gridTemplateColumns,
    gridTemplateRows,
    flow = 'row',
    style,
    theme,
    text,
    sideVariant,
    verticalPadding,
    topPadding,
    bottomPadding,
    rightPadding,
    leftPadding,
    sidePadding,
    justifyItems,
    alignItems,
    justifyContent,
    alignContent,
    fullscreen,
    withBottomDevider,
    color,
    maxWidth,
    backgroundColor,
    ...props
  }: ViewProps<T>,
  ref: ForwardedRef<T>
) => {
  return (
    <Tag
      className={cx(
        styles.view,
        sideVariant && styles[sideVariant],
        fullscreen && styles[fullscreen],
        withBottomDevider && styles.withBottomDevider,
        theme,
        text && textStyles[text],
        className
      )}
      style={{
        backgroundColor,
        gridTemplateRows,
        gridTemplateColumns,
        gridAutoFlow: flow,
        justifyItems,
        alignItems,
        justifyContent,
        alignContent,
        paddingLeft: leftPadding || sidePadding,
        paddingRight: rightPadding || sidePadding,
        paddingTop: topPadding || verticalPadding,
        paddingBottom: bottomPadding || verticalPadding,
        gap,
        color,
        maxWidth,
        ...style,
      }}
      {...props}
      ref={ref}
    />
  );
};

export const View = forwardRef(ViewInner);
