import cx from 'classnames';
import React, {
  ButtonHTMLAttributes,
  MouseEvent,
  PropsWithChildren,
  useMemo,
} from 'react';
import { COLORS } from '../../constants/colors';
import { ICONS } from '../../constants/icons';
import { getErrorMessage } from '../../lib/firewatch-script/getErrorMessage';
import { Icon, getIcon } from '../Icon/Icon';
import { Text, TextVariant } from '../Text/Text';
import textStyles from '../Text/Text.module.scss';
import styles from './Button.module.scss';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'icon'
  | 'none';
export type ButtonAlign = 'center' | 'left' | 'center-icon-right';

export interface ButtonProps
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  variant?: ButtonVariant;
  align?: ButtonAlign;
  icon?: ICONS | string;
  text?: TextVariant;
  underline?: boolean;
  error?: any;
  tag?: string;
}

export const ButtonInner = ({ align = 'center', icon, children }: ButtonProps) => {
  const ic = getIcon(icon) ? (
    <Icon className={styles.withIcon} variant={icon as ICONS} />
  ) : icon ? (
    <img alt="" src={icon as string} className={styles.withIcon} />
  ) : undefined;
  return (
    <>
      {align === 'center' && ic}
      {children}
      {(align === 'left' || align === 'center-icon-right') && ic}
    </>
  );
};

export const getButtonClassName = (
  { className, align = 'center', variant, text, underline, tag }: ButtonProps,
  isLink = false
) => {
  return cx(
    styles.button,
    !isLink && styles.buttonOnly,
    isLink && styles.linkOnly,
    underline && styles.underline,
    variant && styles[variant],
    text && textStyles[text],
    styles[align],
    tag,
    className
  );
};

/**
 * Primary UI component for user interaction
 */
export const Button = (initialProps: ButtonProps) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    className,
    align,
    variant,
    type = 'button',
    icon,
    text,
    underline,
    error,
    children,
    tag,
    onClick,
    ...props
  } = initialProps;
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const errorMessage = useMemo(() => getErrorMessage(error), [error]);
  const clickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
  };
  return (
    <>
      <button
        className={getButtonClassName(initialProps)}
        type={type}
        onClick={clickHandler}
        data-tag={tag}
        {...props}
      >
        <ButtonInner {...initialProps} />
      </button>
      {errorMessage && (
        <Text variant="legal" color={COLORS.RED_01} as="div">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
