import cx from 'classnames';
import {
  ImgHTMLAttributes,
  SyntheticEvent,
  TransitionEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styles from './SplashBackground.module.scss';

interface SplashBackgroundProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  open?: boolean;
  isMobile?: boolean;
  visible?: boolean;
  onHide?: () => void;
  onOpen?: () => void;
  onShow?: () => void;
}

export const SplashBackground = ({
  open,
  isMobile,
  visible,
  className,
  alt = '',
  onHide,
  onLoad,
  onOpen,
  onShow,
  onTransitionEnd,
  ...props
}: SplashBackgroundProps) => {
  const src = useMemo(
    () => (isMobile ? '/landing.jpg' : '/landing-lg.jpg'),
    [isMobile]
  );
  const [loaded, setLoaded] = useState(false);
  const [rendered, setRendered] = useState(visible);

  const onLoadImage = (event: SyntheticEvent<HTMLImageElement>) => {
    onLoad?.(event);
    setLoaded(true);
  };

  const onTransitionEndImage = (event: TransitionEvent<HTMLImageElement>) => {
    onTransitionEnd?.(event);
    const { propertyName } = event;
    propertyName === 'opacity' && onShow?.();
    propertyName === 'transform' && open && onOpen?.();
    propertyName === 'transform' && !open && onHide?.();
  };

  useEffect(() => {
    if (visible && !rendered) {
      setRendered(true);
    }
  }, [visible]);

  return rendered ? (
    <img
      className={cx(
        styles.img,
        loaded && visible && styles.visible,
        loaded && open && styles.open,
        className
      )}
      src={src}
      alt={alt}
      onLoad={onLoadImage}
      onTransitionEnd={onTransitionEndImage}
      {...props}
    />
  ) : null;
};
