import { useRouter } from 'next/router';
import { AnchorHTMLAttributes, MouseEvent, PropsWithChildren, useMemo } from 'react';
import { COLORS } from '../../constants/colors';
import { ICONS } from '../../constants/icons';
import { ROUTES } from '../../constants/routes';
import { useAppContext } from '../../contexts/AppContext/AppContext';
import { useCheckoutContext } from '../../contexts/CheckoutContext/CheckoutContext';
import { useTimeout } from '../../hooks/useTimeout';
import { getErrorMessage } from '../../lib/firewatch-script/getErrorMessage';
import { Text, TextVariant } from '../Text/Text';
import {
  ButtonAlign,
  ButtonVariant,
  ButtonInner,
  getButtonClassName,
  ButtonProps,
} from './Button';

export interface LinkButtonProps
  extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  variant?: ButtonVariant;
  align?: ButtonAlign;
  icon?: ICONS | string;
  text?: TextVariant;
  underline?: boolean;
  error?: any;
  tag?: string;
  delay?: number;
}

/**
 * Primary UI component for user interaction
 */
export const LinkButton = (initialProps: LinkButtonProps) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    className,
    align,
    variant,
    icon,
    href,
    target,
    onClick,
    underline,
    error,
    children,
    tag,
    delay,
    ...props
  } = initialProps;
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const buttonProps = initialProps as ButtonProps;

  const { route, push, replace } = useRouter();
  const { isEmailSubmitted, setTempLink, order } = useCheckoutContext();
  const { isPage } = useAppContext();
  const { tidRef, killTimeout } = useTimeout();

  const clickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    killTimeout();
    if (onClick) onClick(event);
    if (!href) return;
    if (Object.values(ROUTES).some((path) => href.startsWith(path)) && !target) {
      event.preventDefault();

      // If current route is confirmation page but not email is submitted yet,
      // ignore and show close confirmation modal
      if (isPage.confirmation && order && !isEmailSubmitted) {
        setTempLink(href);
        return;
      }

      const go = () => {
        // if the current page is confirmation, replace instead of push
        if (isPage.confirmation) {
          replace(href);
        } else {
          push(href);
        }
      };

      if (!delay) {
        go();
      } else {
        tidRef.current = setTimeout(go, delay);
      }
    }
  };

  const isActive = href && route.startsWith(href);
  const errorMessage = useMemo(() => getErrorMessage(error), [error]);

  return (
    <>
      <a
        className={getButtonClassName(buttonProps, true)}
        href={isActive ? undefined : href}
        target={target}
        onClick={clickHandler}
        data-tag={tag}
        {...props}
        aria-disabled={isActive || props['aria-disabled']}
      >
        <ButtonInner {...buttonProps} />
      </a>
      {errorMessage && (
        <Text variant="legal" color={COLORS.RED_01} as="div">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
