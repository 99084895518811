import Cookies from 'js-cookie';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { CookieConsent } from '../../components/CookieConsent/CookieConsent';
import { COOKIE_CONSENT, GTM_ID, Theme } from '../../constants/config';
import { getIsPage } from './getIsPage';
import { useMatchMedia } from './useMatchMedia';

export interface IsPage {
  about?: boolean;
  admin?: boolean;
  article?: boolean;
  confirmation?: boolean;
  landing?: boolean;
  map?: boolean;
  landscapes?: boolean;
  privacyPolicy?: boolean;
  regions?: boolean;
  initialLanding?: boolean;
  history: string[];
}

export interface AppContextModel {
  isMobile?: boolean;
  isDesktop?: boolean;
  cookie?: boolean;
  theme?: Theme;
  isPage: IsPage;
}

interface AppContextAction {
  setTheme: (theme: Theme) => void;
  setCookie: (cookie: boolean) => void;
}

export type AppContextState = AppContextModel & AppContextAction;

export const initialAppContextModel: AppContextModel = {
  isPage: {
    history: [],
  },
};

const initialAppContextAction: AppContextAction = {
  setTheme: () => {},
  setCookie: () => {},
};

const AppContext: React.Context<AppContextState> =
  React.createContext<AppContextState>({
    ...initialAppContextModel,
    ...initialAppContextAction,
  });

export const AppContextProvider: React.FC<{
  children: ReactNode;
  initialContext?: AppContextModel;
}> = ({ children, initialContext }) => {
  const { route } = useRouter();
  const [appContextModel, setAppContextModel] = useState<AppContextModel>(
    initialContext || {
      ...initialAppContextModel,
      isPage: getIsPage(route, initialAppContextModel.isPage),
    }
  );

  useMatchMedia(setAppContextModel);

  const setTheme = useCallback((theme: Theme) => {
    setAppContextModel((prev) => ({ ...prev, theme }));
  }, []);

  const setCookie = useCallback((cookie: boolean) => {
    setAppContextModel((prev) => ({ ...prev, cookie }));
  }, []);

  const onAcceptCookie = useCallback(() => {
    setCookie(true);
    Cookies.set(COOKIE_CONSENT, 'true');
  }, []);

  useEffect(() => {
    setAppContextModel((prev) => ({
      ...prev,
      isPage: getIsPage(route, prev.isPage),
    }));
  }, [route]);

  useEffect(() => {
    setCookie(Cookies.get(COOKIE_CONSENT) == 'true');
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...appContextModel,
        setTheme,
        setCookie,
      }}
    >
      <div className="app">
        {appContextModel.cookie !== undefined &&
          !appContextModel.isPage.privacyPolicy && (
            <CookieConsent
              cookie={appContextModel.cookie}
              onAccept={onAcceptCookie}
            />
          )}
        {appContextModel.cookie && (
          <Head>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${GTM_ID}');`,
              }}
            />
          </Head>
        )}
        {children}
      </div>
    </AppContext.Provider>
  );
};

export const useAppContext = () => React.useContext(AppContext);
