import cx from 'classnames';
import React from 'react';
import { Text, TextProps } from '../Text/Text';
import styles from './Title.module.scss';

export interface TitleProps extends TextProps {
  divider?: boolean;
}

export const Title = ({
  className,
  divider,
  children,
  align = 'left',
  ...props
}: TitleProps) => {
  return (
    <Text className={cx(styles.title, className)} align={align} {...props}>
      {children}
      {divider ? <span> — </span> : null}
    </Text>
  );
};
