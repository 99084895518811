import React from 'react';
import { Button } from '../Button/Button';
import { Modal, ModalProps } from '../Modal/Modal';
import { Text } from '../Text/Text';
import { Title } from '../Title/Title';
import { View } from '../View/View';

export interface CloseConfirmationModalProps extends Omit<ModalProps, 'children'> {
  onConfirm: () => void;
}

export const CloseConfirmationModal = ({
  onConfirm,
  ...props
}: CloseConfirmationModalProps) => {
  return (
    <Modal closeTag="click_withoutemailpopup_no" {...props}>
      <View topPadding={60} bottomPadding={22} gap={30} sidePadding={22}>
        <Title variant="headline-s" as="h3" align="center">
          Close without email?
        </Title>
        <Text variant="legal" as="p" align="center">
          We’ll use your email to send a purchase confirmation.
        </Text>
        <Text variant="legal" as="p" align="center">
          After exiting you won’t be able to return to this screen.
        </Text>
        <Button
          variant="primary"
          onClick={onConfirm}
          tag="click_withoutemailpopup_yes"
        >
          Yes, close this window
        </Button>
      </View>
    </Modal>
  );
};
