import React, { SVGAttributes } from 'react';
import { COLORS } from '../../constants/colors';
import { Svg } from '../Svg/Svg';

const width = 19;
const height = 18;

export interface CloseIconProps extends SVGAttributes<SVGElement> {
  size?: number;
  title?: string;
  color?: COLORS;
}

export const CloseIcon = ({
  size = width,
  title,
  color = COLORS.CURRENT,
  ...props
}: CloseIconProps) => {
  return (
    <Svg
      width={size}
      height={(height / width) * size}
      viewBox={`0 0 ${width} ${height}`}
      stroke={color}
      strokeWidth="2"
      {...props}
    >
      {title && <title>{title}</title>}
      <path transform="matrix(.7002 .71394 -.7002 .71394 1 2)" d="M0-1h22.411" />
      <path transform="matrix(-.7002 .71394 .7002 .71394 18 2)" d="M0-1h22.411" />
    </Svg>
  );
};
