export enum ROUTES {
  ABOUT = '/about',
  ABOUT_OUR_MISSION = '/about/our-mission',
  ABOUT_OUR_PARTNERSHIP = '/about/our-partnership',
  ADMIN = '/admin',
  ARTICLE = '/articles',
  CONFIRMATION = '/confirmation',
  LANDING = '/',
  LANDSCAPES = '/landscapes',
  MAP = '/map',
  PRIVACY_POLICY = '/privacy-policy',
  REGIONS = '/regions',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
}
