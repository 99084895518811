import cx from 'classnames';
import React, { HTMLAttributes, PropsWithChildren, useEffect, useRef } from 'react';
import { COLORS } from '../../constants/colors';
import { Button } from '../Button/Button';
import { CloseIcon } from '../CloseIcon/CloseIcon';
import { Text } from '../Text/Text';
import styles from './Modal.module.scss';
import Portal from './Portal';

export interface ModalProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  showModal: boolean;
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title?: string;
  viewTag?: string;
  closeTag?: string;
}

export const Modal = ({
  className,
  showModal,
  onClose,
  children,
  title,
  viewTag,
  closeTag,
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const activeElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (showModal) {
      activeElementRef.current = document.activeElement as HTMLElement;
      ref.current?.focus();
    } else {
      activeElementRef.current?.focus();
      activeElementRef.current = null;
    }
  }, [showModal]);

  return (
    <Portal>
      <div>
        {showModal && (
          <div className={cx(styles.overlay, viewTag, className)}>
            <div className={styles.modalBox}>
              {onClose && (
                <Button
                  variant="icon"
                  className={styles.closeButton}
                  onClick={onClose}
                  tag={closeTag}
                >
                  <CloseIcon title="close" color={COLORS.WHITE} />
                </Button>
              )}
              {title && (
                <div className={styles.title}>
                  <Text variant="headline-s">{title}</Text>
                </div>
              )}
              <div className={styles.content} tabIndex={0} ref={ref}>
                {children && React.cloneElement(children as React.ReactElement)}
              </div>
            </div>
          </div>
        )}
      </div>
    </Portal>
  );
};
