const blank = '/blank.png';
const buffering = '/icons/buffering.svg';
const calFire = '/icons/cal-fire.png';
const caretLeft = '/icons/caret-left.svg';
const coin98 = '/icons/coin98.svg';
const email = '/icons/email.svg';
const facebook = '/icons/facebook.svg';
const fire = '/map/fire.svg';
const forestService = '/icons/forest-service.png';
const linkedin = '/icons/linkedin.svg';
const loading = '/icons/loading.svg';
const loadingWhite = '/icons/loading-white.svg';
const lodger = '/icons/lodger.svg';
const lyft = '/icons/lyft.svg';
const ourContracts = '/icons/our-contracts.svg';
const phantom = '/icons/phantom.png';
const play = '/icons/play.svg';
const riskClimateChange = '/icons/risks/climate-change.svg';
const riskDecreasedBiodiversity = '/icons/risks/decreased-biodiversity.svg';
const riskDesertification = '/icons/risks/desertification.svg';
const riskDisease = '/icons/risks/disease.svg';
const riskDrought = '/icons/risks/drought.svg';
const riskErosion = '/icons/risks/erosion.svg';
const riskFireHazard = '/icons/risks/fire-hazard.svg';
const riskFuelLoad = '/icons/risks/fuel-load.svg';
const riskHumanDevelopment = '/icons/risks/human-development.svg';
const riskIceStorm = '/icons/risks/ice-storm.svg';
const riskInvasiveSpecies = '/icons/risks/invasive-species.svg';
const riskLandConversion = '/icons/risks/land-conversion.svg';
const riskLimitedNurseryProduction = '/icons/risks/limited-nursery-production.svg';
const riskOvergrownForest = '/icons/risks/overgrown-forest.svg';
const riskPests = '/icons/risks/pests.svg';
const riskPoorSoilQuality = '/icons/risks/poor-soil-quality.svg';
const riskSeedShortage = '/icons/risks/seed-shortage.svg';
const riskSoilInstability = '/icons/risks/soil-instability.svg';
const riskWind = '/icons/risks/wind.svg';
const share = '/icons/share.svg';
const slideUp = '/icons/slide-up.svg';
const slope = '/icons/slope.svg';
const solana = '/icons/solana.svg';
const solanaLogo = '/icons/solana-logo.png';
const solflare = '/icons/solflare.png';
const sollet = '/icons/sollet.svg';
const solletex = '/icons/sollet-ex.svg';
const sustainability = '/icons/sustainability.svg';
const tier2 = '/icons/tier2.svg';
const tier3 = '/icons/tier3.svg';
const tier4 = '/icons/tier4.svg';
const torus = '/icons/torus.svg';
const twitter = '/icons/twitter.svg';
const verified = '/icons/verified.svg';
const web3Realized = '/icons/web3-realized.svg';

export enum ICONS {
  BLANK = blank as any,
  BUFFERING = buffering as any,
  CAL_FIRE = calFire as any,
  CARET_LEFT = caretLeft as any,
  COIN98 = coin98 as any,
  EMAIL = email as any,
  FACEBOOK = facebook as any,
  FIRE = fire as any,
  FOREST_SERVICE = forestService as any,
  LINKEDIN = linkedin as any,
  LOADING = loading as any,
  LOADING_WHITE = loadingWhite as any,
  LODGER = lodger as any,
  LYFT = lyft as any,
  OUR_CONTRACTS = ourContracts as any,
  PHANTOM = phantom as any,
  PLAY = play as any,
  RISK_CLIMATE_CHANGE = riskClimateChange as any,
  RISK_DECREASED_BIODIVERSITY = riskDecreasedBiodiversity as any,
  RISK_DESERTIFICATION = riskDesertification as any,
  RISK_DISEASE = riskDisease as any,
  RISK_DROUGHT = riskDrought as any,
  RISK_EROSION = riskErosion as any,
  RISK_FIRE_HAZARD = riskFireHazard as any,
  RISK_FUEL_LOAD = riskFuelLoad as any,
  RISK_HUMAN_DEVELOPMENT = riskHumanDevelopment as any,
  RISK_ICE_STORM = riskIceStorm as any,
  RISK_INVASIVE_SPECIES = riskInvasiveSpecies as any,
  RISK_LAND_CONVERSION = riskLandConversion as any,
  RISK_LIMITED_NURSERY_PRODUCTION = riskLimitedNurseryProduction as any,
  RISK_OVERGROWN_FOREST = riskOvergrownForest as any,
  RISK_PESTS = riskPests as any,
  RISK_POOR_SOIL_QUALITY = riskPoorSoilQuality as any,
  RISK_SEED_SHORTAGE = riskSeedShortage as any,
  RISK_SOIL_INSTABILITY = riskSoilInstability as any,
  RISK_WIND = riskWind as any,
  SHARE = share as any,
  SLIDE_UP = slideUp as any,
  SLOPE = slope as any,
  SOLANA = solana as any,
  SOLANA_LOGO = solanaLogo as any,
  SOLFLARE = solflare as any,
  SOLLET = sollet as any,
  SOLLET_EXTENSION = solletex as any,
  SUSTAINABILITY = sustainability as any,
  TIER2 = tier2 as any,
  TIER3 = tier3 as any,
  TIER4 = tier4 as any,
  TORUS = torus as any,
  TWITTER = twitter as any,
  VERIFIED = verified as any,
  WEB3_REALIZED = web3Realized as any,
}

export const iconKeys = Object.keys(ICONS).filter(
  (key) => (key as string).indexOf('.') === -1
);
export const iconValues = Object.keys(ICONS).filter(
  (key) => (key as string).indexOf('.') !== -1
);
