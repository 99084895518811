import cx from 'classnames';
import React, { InputHTMLAttributes, useMemo } from 'react';
import { COLORS } from '../../constants/colors';
import { ICONS } from '../../constants/icons';
import { getErrorMessage } from '../../lib/firewatch-script/getErrorMessage';
import { Icon } from '../Icon/Icon';
import { Text } from '../Text/Text';
import styles from './Input.module.scss';

export type InputVariant = 'md' | 'lg';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * What should be the label for this input box.
   */
  label: string;
  /**
   * Input background color
   */
  bgColor?: COLORS;
  /**
   * Icon to show to the left
   */
  leftIcon?: ICONS;
  /**
   * Icon to show to the right
   */
  rightIcon?: ICONS;
  /**
   * Container class name
   */
  containerClassName?: string;
  /**
   * Size variant
   */
  variant?: InputVariant;
  /**
   * Error
   */
  error?: any;
}

export const Input = ({
  containerClassName,
  className,
  label = 'Name',
  type = 'text',
  bgColor = COLORS.WHITE,
  leftIcon,
  rightIcon,
  variant,
  error,
  style,
  ...props
}: InputProps) => {
  const errorMessage = useMemo(() => getErrorMessage(error), [error]);
  return (
    <>
      <label
        className={cx(
          styles.inputBox,
          leftIcon && styles.withLeftIcon,
          rightIcon && styles.withRightIcon,
          variant && styles[variant],
          containerClassName
        )}
      >
        <Text variant={variant === 'lg' ? 'body-s' : 'eyebrow'}>{label}</Text>
        <input
          type={type}
          style={{ ...style, backgroundColor: bgColor || style?.backgroundColor }}
          className={cx(styles.input, className)}
          {...props}
        />
        {leftIcon && <Icon variant={leftIcon} className={styles.leftIcon} />}
        {rightIcon && <Icon variant={rightIcon} className={styles.rightIcon} />}
      </label>

      {errorMessage && (
        <Text variant="legal" color={COLORS.RED_01} as="div">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
