import cx from 'classnames';
import React, { HTMLAttributes, useEffect } from 'react';
import { CONTACT_EMAIL } from '../../constants/config';
import { useTimeout } from '../../hooks/useTimeout';
import { Button } from '../Button/Button';
import { LinkButton } from '../Button/LinkButton';
import { CloseIcon } from '../CloseIcon/CloseIcon';
import { Text } from '../Text/Text';
import { View } from '../View/View';
import styles from './Toast.module.scss';

export type ToastVariant = 'info' | 'error';

export interface ToastProps extends HTMLAttributes<HTMLDivElement> {
  fromOrderApi?: boolean;
  message: string;
  variant?: ToastVariant;
  onClose?: () => void;
}

export const Toast = ({
  fromOrderApi,
  className,
  message,
  variant = 'info',
  onClose,
  ...props
}: ToastProps) => {
  const isError = variant === 'error';
  const gridTemplateColumns = isError
    ? 'min-content 1fr min-content'
    : '1fr min-content';
  const { tidRef, killTimeout } = useTimeout();

  useEffect(() => {
    killTimeout();
    if (!message || isError || !onClose) return;
    tidRef.current = setTimeout(onClose, 3000);
  }, [isError]);

  if (!message) return null;

  return (
    <div className={cx(styles.toast, styles[variant], className)} {...props}>
      <View
        alignItems="center"
        className={styles.container}
        flow="column"
        justifyContent="space-between"
        rightPadding={4}
        verticalPadding={16}
        gridTemplateColumns={gridTemplateColumns}
      >
        {isError && (
          <View className={styles.icon} alignItems="center" justifyContent="center">
            <svg width="6" height="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0H6L5 18H1L0 0Z" fill="currentColor" />
              <rect x="1" y="20" width="4" height="4" fill="currentColor" />
            </svg>
          </View>
        )}
        <Text variant="body-r">
          {variant === 'info' ? (
            message
          ) : (
            <>
              {/* Use static text (FW-287) */}
              {fromOrderApi
                ? `Looks like something went wrong. If you haven’t received your NFT or your Solana hasn’t returned to your wallet, don’t worry.`
                : `Hmm, looks like something went wrong. Need help?`}
              {` Reach out to us `}
              <LinkButton
                href={`mailto:${CONTACT_EMAIL}`}
                target={CONTACT_EMAIL}
                underline
              >
                {`HERE`}
              </LinkButton>
            </>
          )}
        </Text>
        {onClose && (
          <Button
            className={styles.closeButton}
            onClick={onClose}
            variant="icon"
            tag="click_errortoast_close"
          >
            <CloseIcon title="close" />
          </Button>
        )}
      </View>
    </div>
  );
};
