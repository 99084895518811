import cx from 'classnames';
import React, { HTMLAttributes, ElementType, PropsWithChildren } from 'react';
import { COLORS } from '../../constants/colors';
import styles from './Text.module.scss';
import desktopStyles from './TextDesktop.module.scss';

export type TextVariant =
  | 'h1'
  | 'h3'
  | 'body-r'
  | 'body-s'
  | 'body-xl'
  | 'labels'
  | 'text-links'
  | 'caption'
  | 'price-pill-xs'
  | 'price-s'
  | 'price-l'
  | 'legal'
  | 'headline-l-bold'
  | 'headline-l-light'
  | 'headline-xl-bold'
  | 'headline-l'
  | 'headline-m'
  | 'headline-s'
  | 'headline-xs'
  | 'subhead-l'
  | 'subhead-m'
  | 'subhead-s'
  | 'subhead-xs'
  | 'menu-item'
  | 'menu-option'
  | 'footer-links'
  | 'link-embedded-regular'
  | 'link-embedded-small'
  | 'button-labels'
  | 'all-caps-s'
  | 'body-emphasis-r'
  | 'body-emphasis-s'
  | 'eyebrow'
  | 'video-title';

export type TextAlign = 'left' | 'center' | 'right';

export interface TextProps<T = HTMLElement>
  extends PropsWithChildren<HTMLAttributes<T>> {
  as?: ElementType;
  color?: COLORS;
  variant?: TextVariant;
  desktopVariant?: TextVariant;
  align?: TextAlign;
}

export const Text = <T extends HTMLElement>({
  as: Tag = 'span',
  className,
  color,
  style,
  variant,
  desktopVariant,
  align,
  ...props
}: TextProps<T>) => {
  return (
    <Tag
      className={cx(
        variant && styles[variant],
        desktopVariant && desktopStyles[desktopVariant],
        align && styles[align],
        className
      )}
      style={{ color, ...style }}
      {...props}
    />
  );
};
