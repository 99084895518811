export type { FetchState } from '../types/swr';

export async function assetFetch<T>(url: URL | string, init?: any): Promise<T> {
  const response = await fetch(url, init);
  const { ok, status } = response;
  if (!ok) {
    throw Object.assign(new Error(`Failed to fetch ${url}`), { url, status });
  }
  const data = await response.json();
  return data as T;
}
