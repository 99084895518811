import cx from 'classnames';
import React, {
  CSSProperties,
  TransitionEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTimeout } from '../../hooks/useTimeout';
import { Text, TextProps } from '../Text/Text';
import styles from './RevealText.module.scss';

export interface RevealTextProps extends Omit<TextProps, 'children'> {
  children?: string | number;
  when?: boolean;
  from?: string | number;
  to?: string | number;
  duration?: number;
  delay?: number;
  letterDelay?: number;
  ease?: string;
  loop?: number;
  justifyContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  onShow?: () => void;
  onHide?: () => void;
}

export const RevealText = ({
  children,
  when = false,
  from = '1em',
  to = '-0.8em',
  duration = 500,
  delay = 0,
  letterDelay = 0,
  ease = 'ease-in-out',
  loop,
  justifyContent = 'center',
  onShow,
  onHide,
  ...props
}: RevealTextProps) => {
  const { tidRef, killTimeout } = useTimeout();
  const targetRef = useRef<HTMLSpanElement>(null);
  const prevVisibleRef = useRef<boolean | null>(null);
  const [visible, setVisible] = useState<boolean | null>(null);

  const getElms = useCallback(() => {
    const elms = targetRef.current?.querySelectorAll(`.${styles.text}`);
    if (!elms) return [];
    return [].map.call(elms, (el) => el) as HTMLSpanElement[];
  }, []);

  const onTransitionEnd = useCallback(
    (event: TransitionEvent<HTMLSpanElement>) => {
      const el = event.currentTarget;
      el.style.removeProperty('transition');
      if (!visible) {
        el.style.removeProperty('transform');
        el.style.removeProperty('opacity');
      }

      if (event.propertyName === 'transform' && el.classList.contains('last')) {
        if (onHide) onHide();
        if (loop) {
          if (!visible) {
            setVisible(true);
          } else {
            tidRef.current = setTimeout(() => {
              setVisible(false);
            }, loop);
          }
        }
      }
    },
    [loop, onHide, visible]
  );

  const words = useMemo(() => {
    return getLettersInWordsBySentences(children);
  }, [children]);

  const style = useMemo(() => {
    return {
      '--from': typeof from === 'number' ? `${from}px` : from,
      '--to': typeof to === 'number' ? `${to}px` : to,
      '--ease': ease,
      justifyContent,
    } as CSSProperties;
  }, [from, to, ease, justifyContent]);

  useEffect(() => {
    const prevVisible = prevVisibleRef.current;
    prevVisibleRef.current = visible;
    const isInitial = typeof prevVisible !== 'boolean';
    if (isInitial && !visible) return;
    killTimeout();

    let elms = getElms();

    elms.forEach((el) => {
      el.style.removeProperty('transition');
    });

    tidRef.current = setTimeout(() => {
      elms = getElms();
      elms.forEach((el, index) => {
        const { length } = elms;
        const delayMs = letterDelay ? 0 : Math.max(0, (index * 300) / length);
        el.style.setProperty(
          'transition',
          `all ${duration - delayMs}ms ${
            delayMs || letterDelay * index
          }ms var(--ease)`
        );
        el.style.setProperty('transform', `translateY(${visible ? 0 : 'var(--to)'})`);
        el.style.setProperty('opacity', `${visible ? 1 : 0}`);
      });

      if (onShow) onShow();
    }, delay);
  }, [visible]);

  useEffect(() => {
    setVisible(when);
  }, [when]);

  if (!words.length) return null;

  return (
    <Text {...props}>
      <span ref={targetRef} style={style} className={styles.wordsWrapper}>
        {words.map((words, index) => {
          return (
            <span key={`${words.join('')}${index}`} className={styles.textWrapper}>
              {words.map((val, inx) => {
                const isLast = inx === words.length - 1;
                return (
                  <span
                    key={`${val}${inx}`}
                    {...{ onTransitionEnd }}
                    className={cx(styles.text, isLast && 'last')}
                  >{`${val}`}</span>
                );
              })}
            </span>
          );
        })}
      </span>
    </Text>
  );
};

function getLettersInWordsBySentences(sensences?: string | number): string[][] {
  sensences = sensences?.toString();
  if (!sensences) return [];
  return sensences.split(' ').map((value, index) => {
    // @ts-ignore
    const vals = [...value] as string[];
    if (!vals.length) return [' '];
    return vals.map((v, ind) => {
      return `${ind || !index ? '' : ' '}${v}`;
    });
  });
}
