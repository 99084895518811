import cx from 'classnames';
import React, { ImgHTMLAttributes } from 'react';
import styles from './FireIcon.module.scss';

const defaultSize = 22;
const ratio = 30 / 21;

export interface FireIconProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: number;
  animation?: boolean;
}

export const FireIcon = ({
  size = defaultSize,
  animation,
  width,
  height,
  className,
  alt = '',
  ...props
}: FireIconProps) => {
  const w = width || size;
  const h = height || (typeof w === 'number' ? w : size) * ratio;
  return (
    <img
      src={`/icons/fire.${animation ? 'gif' : 'svg'}`}
      className={cx(styles.icon, className)}
      width={w}
      height={h}
      alt={alt}
      {...props}
    />
  );
};
