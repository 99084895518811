import { useRouter } from 'next/router';
import { useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { API_URL } from '../constants/config';
import { apiFetch } from '../services/api';
import { Graph, Landscape, Region } from '../types/api';
import { FetchState } from '../types/swr';
import { onError } from '../utils/swrUtils';
import { useGeometries } from './useGeometries';

export interface GraphMutations {
  fetch: () => void;
}

export type GraphState = {
  [key in keyof Graph]?: Graph[key];
} & {
  landscapesById?: Map<number, Landscape>;
  selectedLandscape?: Landscape;
  selectedLandscapes?: Landscape[];
  selectedRegion?: Region;
  availableCount?: number;
  selectedAvailableCount?: number;
};

export type GraphHook = () => GraphState & GraphMutations & FetchState;

const url = `${API_URL}/graph`;

/**
 * React hook for using the firewatch graph api.
 */
export const useGraph: GraphHook = () => {
  const { query } = useRouter();
  const { mutate } = useSWRConfig();
  const { data: graph, error } = useSWR<Graph>(url, apiFetch, { onError });
  const { landscapeGeometries, regionGeometries } = useGeometries();

  const filteredGraph = useMemo(() => {
    if (!graph || !landscapeGeometries || !regionGeometries) return undefined;

    // regions
    const regions: Region[] = graph.regions.map((region) => {
      const geometry = regionGeometries.get(region.id) || region.geometry;
      return { ...region, geometry };
    });

    // regionsById
    const regionsById = regions.reduce(
      (prev, region) => prev.set(region.id, region),
      new Map<number, Region>()
    );

    // landscapes / availableCount
    const landscapes: Landscape[] = graph.landscapes
      .map((landscape) => {
        const geometry = landscapeGeometries.get(landscape.id) || landscape.geometry;
        return { ...landscape, geometry };
      })
      .sort((a, b) => a.regionId - b.regionId) // region id
      .sort((a, b) => (a.sold === b.sold ? 0 : a.sold ? 1 : -1)); // sold condition

    const availableCount = landscapes.filter((l) => !l.sold).length;

    // landscapesById
    const landscapesById = landscapes.reduce(
      (prev, landscape) => prev.set(landscape.id, landscape),
      new Map<number, Landscape>()
    );

    // landscapesByRegionId / availableCountByRegionId
    const availableCountByRegionId = new Map<number, number>();
    const landscapesByRegionId = regions.reduce((prev, { id }) => {
      const items = landscapes.filter(({ regionId }) => id === regionId);
      availableCountByRegionId.set(id, items.filter((l) => !l.sold).length);
      return prev.set(id, items);
    }, new Map<number, Landscape[]>());

    return {
      landscapes,
      landscapesById,
      landscapesByRegionId,
      regions,
      regionsById,
      availableCount,
      availableCountByRegionId,
    };
  }, [graph, landscapeGeometries, regionGeometries]);

  const selectedGraph = useMemo(() => {
    if (!filteredGraph) return undefined;

    const lid = Number(query.lid);
    const rid = Number(query.rid);

    const selectedLandscape = filteredGraph.landscapesById?.get(lid);
    const selectedRegionId = rid || selectedLandscape?.regionId || -1;
    const selectedRegion = filteredGraph.regionsById?.get(selectedRegionId);
    const selectedLandscapes =
      filteredGraph.landscapesByRegionId?.get(selectedRegionId);
    const selectedAvailableCount =
      filteredGraph.availableCountByRegionId?.get(selectedRegionId);

    return {
      selectedLandscape,
      selectedRegion,
      selectedLandscapes,
      selectedAvailableCount,
    };
  }, [filteredGraph, query]);

  return {
    ...graph,
    ...filteredGraph,
    ...selectedGraph,
    error,
    fetch: () => mutate(url),
    isFetching: !error && !graph,
  };
};
