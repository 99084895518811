import { useRouter } from 'next/router';
import React, { ReactNode, useMemo } from 'react';
import { CloseConfirmationModal } from '../../components/CloseConfirmationModal/CloseConfirmationModal';
import { useGraph } from '../../hooks/useGraph';
import { Landscape, Order } from '../../types/api';
import { useOverlayContext } from '../OverlayContext/OverlayContext';

export type CheckoutContextModel = {
  /**
   * Landscape item
   */
  landscape?: Landscape;
  /**
   * Order item
   */
  order?: Order;
  /**
   * a condition whether submitted email address on confirmation page
   */
  isEmailSubmitted?: boolean;
  /**
   * a link to navigate when user confirm to close confirmation page without submitting email
   * https://www.figma.com/file/z0yHhFX1GrOnaJA7tIXSAP/FIRE.WATCH?node-id=4648%3A129528
   */
  tempLink?: string;
};

interface CheckoutContextAction {
  resetCheckout: () => void;
  setEmailSubmitted: (submitted?: true) => void;
  setOrder: (order: Order) => void;
  setTempLink: (link: string) => void;
}

export type CheckoutContextState = CheckoutContextModel & CheckoutContextAction;

export const initialCheckoutContextModel: CheckoutContextModel = {};

const initialCheckoutContextAction: CheckoutContextAction = {
  resetCheckout: () => {},
  setEmailSubmitted: () => {},
  setOrder: () => {},
  setTempLink: () => {},
};

const CheckoutContext: React.Context<CheckoutContextState> =
  React.createContext<CheckoutContextState>({
    ...initialCheckoutContextModel,
    ...initialCheckoutContextAction,
  });

export const CheckoutContextProvider: React.FC<{
  children: ReactNode;
  initialContext?: CheckoutContextModel;
}> = ({ children, initialContext }) => {
  const { replace } = useRouter();
  const { fetch, landscapesById } = useGraph();
  const { setToast } = useOverlayContext();
  const [checkoutContextModel, setCheckoutContextModel] =
    React.useState<CheckoutContextModel>(
      initialContext || initialCheckoutContextModel
    );

  const landscape = useMemo(() => {
    if (!landscapesById || !checkoutContextModel.order) return;
    return landscapesById.get(checkoutContextModel.order.landscapeId);
  }, [landscapesById, checkoutContextModel.order]);

  const resetCheckout = () => setCheckoutContextModel(initialCheckoutContextModel);

  const setOrder = (order: Order) => {
    setCheckoutContextModel({ order });
    fetch();
  };

  const setEmailSubmitted = (isEmailSubmitted = true) => {
    setCheckoutContextModel((prev) => ({ ...prev, isEmailSubmitted }));
    setToast({ message: 'Email has been sent!' });
  };

  const setTempLink = (tempLink: string) =>
    setCheckoutContextModel((prev) => ({ ...prev, tempLink }));

  return (
    <CheckoutContext.Provider
      value={{
        ...checkoutContextModel,
        landscape,
        resetCheckout,
        setOrder,
        setEmailSubmitted,
        setTempLink,
      }}
    >
      {children}
      <CloseConfirmationModal
        viewTag="view_withoutemail_popup"
        showModal={!!checkoutContextModel.tempLink}
        onClose={() =>
          setCheckoutContextModel(({ order, ...prev }) => {
            if (!order) return prev;
            return {
              ...prev,
              order,
              tempLink: undefined,
            };
          })
        }
        onConfirm={() => {
          if (checkoutContextModel?.tempLink) {
            replace(checkoutContextModel.tempLink);
          }
        }}
      />
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => React.useContext(CheckoutContext);
