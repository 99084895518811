import React, { SVGProps } from 'react';

export interface LogoProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

export const Svg = ({
  children,
  fill = 'none',
  title,
  xmlns = 'http://www.w3.org/2000/svg',
  ...props
}: LogoProps) => {
  return (
    <svg {...{ fill, xmlns }} {...props}>
      {title && <title>{title}</title>}
      {children}
    </svg>
  );
};
