import React, {
  CSSProperties,
  TransitionEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTimeout } from '../../hooks/useTimeout';
import { Text, TextProps } from '../Text/Text';
import styles from './Reveal.module.scss';

export interface RevealProps extends TextProps {
  when?: boolean;
  from?: string | number;
  to?: string | number;
  duration?: number;
  delay?: number;
  ease?: string;
  loop?: number;
  onShow?: () => void;
  onHide?: () => void;
}

export const Reveal = ({
  children,
  when = false,
  from = '1em',
  to = '-0.8em',
  duration = 500,
  delay = 0,
  ease = 'ease-in-out',
  loop,
  style,
  onShow,
  onHide,
  ...props
}: RevealProps) => {
  const { tidRef, killTimeout } = useTimeout();
  const targetRef = useRef<HTMLSpanElement>(null);
  const prevVisibleRef = useRef<boolean | null>(null);
  const [visible, setVisible] = useState<boolean | null>(null);

  const onTransitionEnd = (event: TransitionEvent<HTMLSpanElement>) => {
    const el = event.currentTarget;
    el.style.removeProperty('transition');
    if (!visible) {
      el.style.removeProperty('transform');
      el.style.removeProperty('opacity');
    }

    if (event.propertyName === 'transform') {
      if (onHide) onHide();
      if (loop) {
        if (!visible) {
          setVisible(true);
        } else {
          tidRef.current = setTimeout(() => {
            setVisible(false);
          }, loop);
        }
      }
    }
  };

  useEffect(() => {
    const prevVisible = prevVisibleRef.current;
    prevVisibleRef.current = visible;
    const isInitial = typeof prevVisible !== 'boolean';
    if (isInitial && !visible) return;
    killTimeout();

    const el = targetRef.current;
    if (!el) return;

    el.style.removeProperty('transition');

    tidRef.current = setTimeout(() => {
      el.style.setProperty('transition', `all ${duration}ms var(--ease)`);
      el.style.setProperty('transform', `translateY(${visible ? 0 : 'var(--to)'})`);
      el.style.setProperty('opacity', `${visible ? 1 : 0}`);

      if (onShow) onShow();
    }, delay);
  }, [visible]);

  useEffect(() => {
    setVisible(when);
  }, [when]);

  if (!children) return null;

  return (
    <Text
      {...props}
      style={
        {
          '--from': typeof from === 'number' ? `${from}px` : from,
          '--to': typeof to === 'number' ? `${to}px` : to,
          '--ease': ease,
          ...style,
        } as CSSProperties
      }
    >
      <span {...{ onTransitionEnd }} className={styles.reveal} ref={targetRef}>
        {children}
      </span>
    </Text>
  );
};
