import cx from 'classnames';
import { ElementType } from 'react';
import { Button } from '../Button/Button';
import { CloseIcon } from '../CloseIcon/CloseIcon';
import { Text, TextVariant } from '../Text/Text';
import { View, ViewProps } from '../View/View';
import styles from './Prompt.module.scss';

export interface PromptProps extends ViewProps<HTMLElement> {
  action?: string;
  actionTag?: string;
  onResolve?: () => void;
  onReject?: () => void;
  titleAs?: ElementType;
  titleVariant?: TextVariant;
  title?: string;
}

export const Prompt = ({
  action,
  actionTag,
  as = 'section',
  children,
  className,
  gap = 8,
  onResolve,
  onReject,
  sidePadding = 16,
  title,
  titleAs = 'div',
  titleVariant = 'headline-s',
  verticalPadding = 16,
  ...props
}: PromptProps) => {
  return (
    <View
      className={cx(styles.root, className)}
      {...{ as, gap, sidePadding, verticalPadding, ...props }}
    >
      <Button variant="icon" className={styles.closeButton} onClick={onReject}>
        <CloseIcon />
      </Button>
      {title && (
        <Text as={titleAs} className={styles.title} variant={titleVariant}>
          {title}
        </Text>
      )}
      {children}
      {action && (
        <Button
          className={styles.actionButton}
          tag={actionTag}
          variant="primary"
          onClick={onResolve}
        >
          {action}
        </Button>
      )}
    </View>
  );
};
