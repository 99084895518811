import { MultiPolygon } from 'geojson';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';
import { FetchState, assetFetch } from '../services/asset';
import { BorderFeature, FeatureCollection, GeometryFeature } from '../types/geojson';
import { onError } from '../utils/swrUtils';

export interface GeometriesState {
  landscapeGeometries?: Map<number, MultiPolygon>;
  regionGeometries?: Map<number, MultiPolygon>;
  regionBorders?: BorderFeature[];
}
export type GeometriesHook = () => GeometriesState & FetchState;

const landscapeGeometriesUrl = '/map/landscape-geometries.geojson';
const regionBordersUrl = '/map/region-borders.geojson';
const regionGeometriesUrl = '/map/region-geometries.geojson';

export const useGeometries: GeometriesHook = () => {
  const {
    data: { features: landscapeFeatures } = {},
    error: landscapeGeometriesError,
  } = useSWRImmutable<FeatureCollection<GeometryFeature>>(
    landscapeGeometriesUrl,
    assetFetch,
    { onError }
  );

  const { data: { features: regionFeatures } = {}, error: regionGeometriesError } =
    useSWRImmutable<FeatureCollection<GeometryFeature>>(
      regionGeometriesUrl,
      assetFetch,
      { onError }
    );

  const { data: { features: regionBorders } = {}, error: regionBordersError } =
    useSWRImmutable<FeatureCollection<BorderFeature>>(regionBordersUrl, assetFetch, {
      onError,
    });

  const error =
    landscapeGeometriesError || regionGeometriesError || regionBordersError;
  const isFetching =
    !error && !landscapeFeatures && !regionFeatures && !regionBorders;

  const landscapeGeometries = useMemo(
    () =>
      landscapeFeatures?.reduce(
        (prev, { id, geometry }: GeometryFeature) => prev.set(id, geometry),
        new Map<number, MultiPolygon>()
      ),
    [landscapeFeatures]
  );

  const regionGeometries = useMemo(
    () =>
      regionFeatures?.reduce(
        (prev, { id, geometry }: GeometryFeature) => prev.set(id, geometry),
        new Map<number, MultiPolygon>()
      ),
    [regionFeatures]
  );

  return {
    error,
    isFetching,
    landscapeGeometries,
    regionBorders,
    regionGeometries,
  };
};
