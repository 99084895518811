export const BREAK_POINT = 768;

export const HEADER_HEIGHT = 53;

export const HEADER_TITLE = 'FIRE 🔥 WATCH';
export const HEADER_DESCRIPTION =
  'NFT landscapes that fund wildfire prevention, forest management, and reforestation efforts across California.';

export const OG_TITLE = 'FIREWATCH';
export const OG_DESCRIPTION =
  'Only in America can you adopt a highway, but not a forest — until now. Introducing FIREWATCH: 1004 sustainable NFTs funding wildfire prevention in California, minted with intention on Solana’s eco-friendly blockchain. In partnership with OneTreePlanted, 100% of the proceeds go to sponsoring, educating and protecting California’s most vulnerable habitats. Start fighting: firewatch.fund';
export const OG_IMAGE_URL = 'https://firewatch.fund/firewatch-social.png';
export const OG_IMAGE_WIDTH = '1200';
export const OG_IMAGE_HEIGHT = '630';

export const MEDIUM_PUBLICATION_NAME = 'ymedialabs-innovation';
export const MEDIUM_TAG_NAME = 'podcast';

export type Theme = 'light' | 'dark';

export const defaultTheme: Theme = 'light';

export const oneTreePlanted = 'One Tree Planted™';

export const API_URL = `${process.env.NEXT_PUBLIC_API_URL || '/api-mocks'}`;

export const isDevnet = `${process.env.NEXT_PUBLIC_NETWORK}` === 'devnet';

export const AUTHORITY_KEY = `${process.env.NEXT_PUBLIC_AUTHORITY_KEY}`;

export const RPC_ENDPOINT = `${process.env.NEXT_PUBLIC_SOLANA_RPC_ENDPOINT}`;

export const GTM_ID = `${process.env.NEXT_PUBLIC_GTM}`;

export const COOKIE_CONSENT = 'FWFCC';
export const COOKIE_FIGHTING = 'FWF';
export const COOKIE_GATE = 'FWG';

export const CONTACT_EMAIL = 'contact@firewatch.fund';
