export enum COLORS {
  TRANSPARENT = 'transparent',
  CURRENT = 'currentColor',
  FOREST_GREEN = 'var(--forest-green)',
  WHITE = 'var(--white)',
  BLACK = 'var(--black)',
  BEACH_01_1 = 'var(--beach-01-1)',
  BEACH_01_2 = 'var(--beach-01-2)',
  BEACH_01_3 = 'var(--beach-01-3)',
  BEACH_01_4 = 'var(--beach-01-4)',
  BLUE_01 = 'var(--blue-01)',
  BLUE_02 = 'var(--blue-02)',
  BLUE_03 = 'var(--blue-03)',
  BLUE_04 = 'var(--blue-04)',
  BROWN1_01 = 'var(--brown1-01)',
  BROWN1_02 = 'var(--brown1-02)',
  BROWN2_00 = 'var(--brown2-00)',
  BROWN2_01 = 'var(--brown2-01)',
  BROWN2_02 = 'var(--brown2-02)',
  BROWN2_03 = 'var(--brown2-03)',
  GREEN1_01 = 'var(--green1-01)',
  GREEN1_02 = 'var(--green1-02)',
  GREEN1_03 = 'var(--green1-03)',
  GREEN1_04 = 'var(--green1-04)',
  GREEN2_01 = 'var(--green2-01)',
  GREEN2_02 = 'var(--green2-02)',
  GREEN2_03 = 'var(--green2-03)',
  GREEN2_04 = 'var(--green2-04)',
  GREY_01 = 'var(--grey-01)',
  GREY_02 = 'var(--grey-02)',
  GREY_03 = 'var(--grey-03)',
  NEUTRAL_01 = 'var(--neutral-01)',
  NEUTRAL_02 = 'var(--neutral-02)',
  PURPLE_01 = 'var(--purple-01)',
  PURPLE_02 = 'var(--purple-02)',
  PURPLE_03 = 'var(--purple-03)',
  PURPLE_04 = 'var(--purple-04)',
  RED_01 = 'var(--red-01)',
}
