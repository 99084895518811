import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import 'swiper/scss';
import 'swiper/scss/mousewheel';
import 'swiper/scss/pagination';
import { Login } from '../components/Login/Login';
import { AppContextProvider } from '../contexts/AppContext/AppContext';
import { CheckoutContextProvider } from '../contexts/CheckoutContext/CheckoutContext';
import { OverlayContextProvider } from '../contexts/OverlayContext/OverlayContext';
import '../styles/globals.scss';

const App = dynamic(async () => await import('../components/App/App'), {
  ssr: false,
});

const withPasswordProtect = () => {
  const ProtectedApp = (props: AppProps) => {
    const [isAuth, setAuth] = useState<undefined | boolean>(undefined);

    const checkLoggedIn = async () => {
      try {
        const res = await fetch('/api/passwordCheck', {
          credentials: 'include',
        });

        if (res.status === 200) {
          setAuth(true);
        } else {
          setAuth(false);
        }
      } catch (e) {
        setAuth(false);
      }
    };

    useEffect(() => {
      checkLoggedIn();
    }, []);

    if (isAuth === undefined) return null;
    if (!isAuth) return <Login onSuccess={checkLoggedIn} />;
    return <App {...props} />;
  };

  return ProtectedApp;
};

const Container = process.env.PASSWORD_PROTECT ? withPasswordProtect() : App;

function Root(props: AppProps) {
  return (
    <AppContextProvider>
      <CheckoutContextProvider>
        <OverlayContextProvider>
          <Container {...props} />
        </OverlayContextProvider>
      </CheckoutContextProvider>
    </AppContextProvider>
  );
}

export default Root;
