import React, { ElementType, useEffect, useState } from 'react';
import { ROUTES } from '../../constants/routes';
import { LinkButton } from '../Button/LinkButton';
import { Prompt } from '../Prompt/Prompt';
import { Text, TextVariant } from '../Text/Text';
import { ViewProps } from '../View/View';
import styles from './CookieConsent.module.scss';

export interface CookieConsentProps extends ViewProps<HTMLElement> {
  cookie: boolean;
  titleAs?: ElementType;
  titleVariant?: TextVariant;
  title?: string;
  onAccept?: () => void;
}

export const CookieConsent = ({
  cookie,
  title = 'We use cookies',
  children,
  onAccept,
  ...props
}: CookieConsentProps) => {
  const [visible, setVisible] = useState(!cookie);

  const onClick = () => {
    setVisible(false);
    onAccept?.();
  };

  useEffect(() => {
    setVisible(!cookie);
  }, [cookie]);

  if (!visible) return null;

  return (
    <Prompt
      action="Accept"
      actionTag="click_accept"
      className={styles.root}
      onReject={() => setVisible(false)}
      onResolve={onClick}
      title={title}
      {...props}
    >
      <Text as="p" variant="body-s">
        {`Cookies help us deliver the best experience. By using our website, you agree to the use of cookies. `}
        <LinkButton
          underline
          href={ROUTES.PRIVACY_POLICY}
          target={ROUTES.PRIVACY_POLICY}
        >
          Find out more.
        </LinkButton>
      </Text>
      {children}
    </Prompt>
  );
};
