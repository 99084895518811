import { captureException } from '@sentry/nextjs';
import { API_URL } from '../constants/config';
import { Order } from '../types/api';

export interface ApiError extends Error {
  status?: number;
  url?: URL | string;
}

export interface ApiResponse<T, E = ApiError> {
  data?: T;
  error?: E;
}

export async function apiFetch<T>(url: URL | string, init?: RequestInit): Promise<T> {
  const response = await fetch(url, init);
  const { status, ok } = response;
  const { data, error } = await response.json();
  if (error || !ok) {
    throw Object.assign(new Error(`Failed to fetch ${url}`), error, { url, status });
  }
  return data as T;
}

export interface PostOrderError extends ApiError {
  refundSignature?: string;
}

export interface PostOrderError extends ApiError {
  refundSignature?: string;
}

export const postOrder = async (
  input: Order
): Promise<ApiResponse<Order, PostOrderError>> => {
  const url = `${API_URL}/orders`;
  try {
    const data = await apiFetch<Order>(url, {
      method: 'POST',
      body: JSON.stringify(input),
    });
    return { data };
  } catch (error) {
    captureException(error);
    return { error: error as PostOrderError };
  }
};

export const patchOrder = async (input: Order): Promise<ApiResponse<Order>> => {
  const url = `${API_URL}/orders/${input.id}`;
  try {
    const data = await apiFetch<Order>(url, {
      method: 'PATCH',
      body: JSON.stringify(input),
    });
    return { data };
  } catch (error) {
    captureException(error);
    return { error: error as ApiError };
  }
};
